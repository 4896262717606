import { useLocation } from "@reach/router"
import queryString from "query-string"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-react-intl"
import React, { useContext, useState } from "react"

import { useIntlPathName } from "../../../../hooks/useIntlPathName"
import { HomeLayout, Tab } from "../HomeLayout"
import { LearnApiPageContent } from "./LearnApiPageContent"

const LearnApiPage: React.FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const pathname = useIntlPathName()

  const handleTabChange = (tab: Tab) => {
    const query = queryString.parse(location.search)
    navigate(
      queryString.stringifyUrl({
        url: tab.path,
        query,
      })
    )
  }

  return (
    <>
      <HomeLayout
        variant={"default"}
        activeTab="learn-api"
        onTabChange={handleTabChange}
      >
        <LearnApiPageContent />
      </HomeLayout>
    </>
  )
}
export { LearnApiPage }
