import { FormattedMessage } from "gatsby-plugin-react-intl"
import React from "react"

import * as S from "./style"

const LearnApiPageContent: React.FC = () => {
  return (
    <S.Main>
      <S.Wrapper>
        <S.Header>
          <FormattedMessage
            defaultMessage="Learn API - what is it and why to use it?"
            description="information"
          />
        </S.Header>
        <S.DescriptionLinkContainer>
          <S.DescriptionLink href="https://github.com/learntray/learn-api">
            <FormattedMessage
              defaultMessage="Learn API specification on GitHub >"
              description="information link"
            />
          </S.DescriptionLink>
        </S.DescriptionLinkContainer>
        <S.Divider />
        <S.Subheader>
          <FormattedMessage
            defaultMessage="The problem"
            description="information"
          />
        </S.Subheader>
        <S.Description>
          <FormattedMessage
            defaultMessage="As you can see, all around the Internet there exist many different learning websites. Some of them are simple, some of them are more sofisticated. We can search for them on the Internet like any other websites on the Internet. It is fine. But, there exists one problem. Even big problem. The planning and managing problem of that learing content - simply put, it is not easy to plan and manage learing from big number of different sources."
            description="information"
          />
        </S.Description>
        <S.Divider />
        <S.Subheader>
          <FormattedMessage
            defaultMessage="The solution"
            description="information"
          />
        </S.Subheader>
        <S.Description>
          <FormattedMessage
            defaultMessage="The current solutions already exist, usually they are trying to condense learing into bigger platforms like Udemy, giving features facilitating users management or learning management. Although, it is not ideal solution. Our approach is different. Instead of trying to make one place for learing and for management, we want to divide that place into two parts. First part as one place for only management and planning like LearnTray and second part as many different places for learing, many different websites. So Learn API is the solution which could be used by LearnTray to plan learing for users, and implemented by learing websites creators, giving possibility for learining content creators do what they are best in, to allow creators create excellent learing experience on their own website, with their own user interface, which is adjusted in the best way to topic they learn. And still making it very easy to plan and manage learing from such many different sources."
            description="information"
          />
        </S.Description>
        <S.Divider />
        <S.Subheader>
          <FormattedMessage
            defaultMessage="Advantages for learning content creators"
            description="information"
          />
        </S.Subheader>
        <S.Description>
          <FormattedMessage
            defaultMessage="If you are learning website creator, we think with the solution we are trying to create, we could give you more leads, more users and more freedom in creating your learing content, as we won't force you to adjust your learing content to our website, you are free to do anyting you want on your website. Finally, we think it could even improve users satisfaction and improve their learing outcomes."
            description="information"
          />
        </S.Description>
        <S.Divider />
        <S.Subheader>
          <FormattedMessage
            defaultMessage="What is the current state of Learn API?"
            description="information"
          />
        </S.Subheader>
        <S.Description>
          <FormattedMessage
            defaultMessage="After research and development, we have created Learn API RFC. {checkOutLearnApi} It is the document, in which we describe the proposal API, which is possible to be implemented by your learning website. If you would like to use this API, you can implement it according to the described specification, but we would like also to discuss it with you, gather your feedback and answear your questions as well as make it working with tools we create like LearnTray App. Please contact us at {email}."
            description="information"
            values={{
              checkOutLearnApi: (
                <a href="https://github.com/learntray/learn-api">
                  <FormattedMessage
                    defaultMessage="You can check it out here on GitHub."
                    description="information link"
                  />
                </a>
              ),
              email: <b>contact@learntray.com</b>,
            }}
          />
        </S.Description>
      </S.Wrapper>
    </S.Main>
  )
}

export { LearnApiPageContent }
