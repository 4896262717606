import { useIntl } from "gatsby-plugin-react-intl"
import React from "react"
import { SEO } from "../components/atoms/SEO"
import { LearnApiPage } from "../components/sets/home/LearnApiPage"

const Home: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <SEO
        subtitle={intl.formatMessage({
          defaultMessage: "Learn API",
          description: "website subtitle",
        })}
      />
      <LearnApiPage />
    </>
  )
}

export default Home
